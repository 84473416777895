import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppLogo from '../_components/AppLogo';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { alertService } from '../_services';
import { identityAuthenticatorService } from '../_services/identity.authenticator.service'

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(15)
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        // marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function SetupProfile({ history }) {
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const { email, locale } = history.location.state;
    const [isSubmitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const initialValues = {
        email,
        name: '',
        phoneNumber: '',
        companyName: ''
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .max(20, `${t('common.forms.validation.maxCharacter', { max: 20 })}`)
            .required(`${t('common.forms.validation.required')}`),
        phoneNumber: Yup.string().max(20, `${t('common.forms.validation.maxCharacter', { max: 20 })}`),
        companyName: Yup.string().max(20, `${t('common.forms.validation.maxCharacter', { max: 20 })}`),


    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitting(true);
            identityAuthenticatorService.createProfile(values)
                .then(() => {
                    //history.push('setup-password', { email, locale });
                    history.push('login', { locale });
                })
                .catch(error => {

                    if (error.message == 'Failed to fetch') {
                        console.log(error);
                        setErrorMessage(t('service_msg.connection_refused'));
                    }
                    else {
                        switch (error.code) {
                            case 'incomplete_profile':
                                history.push('setup-profile', { email, locale });
                                break;
                            case 'incomplete_verification':
                                history.push('verify-email', { email, locale });
                                break;
                            default:
                                console.log(error.message);
                                alertService.error(t('service_msg.' + error.code));
                                setErrorMessage(t('service_msg.' + error.code));
                        }
                    }

                }).finally(() => {
                    setSubmitting(false);
                });
        },
    });

    useEffect(() => {
        i18n.changeLanguage(locale);
    }, [i18n, locale])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box className={classes.box} pr={10} pl={10} pt={5} pb={5}>
                    <Box mb={3}>
                        <AppLogo />
                    </Box>
                    <Typography component="h4" variant="subtitle1">
                        {t('account.setupProfile_label_1')}
                    </Typography>
                    <form className={classes.form} onSubmit={formik.handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="name"
                            label={t('account.yourName')}
                            name="name"
                            size="small"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="companyName"
                            label={t('account.companyName')}
                            name="companyName"
                            size="small"
                            value={formik.values.companyName}
                            onChange={formik.handleChange}
                            error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                            helperText={formik.touched.companyName && formik.errors.companyName}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="phoneNumber"
                            label={t('account.phoneNumber')}
                            name="phoneNumber"
                            size="small"
                            value={formik.values.phoneNumber}
                            onChange={formik.handleChange}
                            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                        />
                        <Typography variant="body2" color="error">
                            {errorMessage}
                        </Typography>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isSubmitting}
                        >
                            {t('common.forms.nextButton')}
                        </Button>
                    </form>
                </Box>
            </div>
        </Container>
    );
}

export { SetupProfile };