import React, { useEffect, useState } from 'react';
import { alertService, configDynamicService, identitySessionControllerService } from '../_services'

import Container from '@material-ui/core/Container';



function ReturnDefault({ history }) {


    const tokenSession = identitySessionControllerService.tokenSessionValue;

    const [count, setCount] = useState(0);
    //const [displayToken, SetDisplayToken] = useState(0);

    useEffect(() => {
        //console.log(dynamicConfigService.GetUrlDefaultAfterLogin());
        //console.log(identitySessionControllerService.tokenSessionValue.claims["connectionKey"]);
       // window.location.href = configDynamicService.url_return_after_login(identitySessionControllerService.tokenSessionValue.claims["connectionKey"]);
    }, []);

    //let displayToken: any;
    //let displayAgora: any;
    /*
    useEffect(() => {
        let myInterval = setInterval(() => {
            setCount(count + 1);
            //SetDisplayToken(tokenSession.accessToken);
            console.log(new Date(Date.now()).toUTCString());
            //displayToken = tokenSession.accessToken;
            displayAgora = new Date(Date.now()).toUTCString();

        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });
    */


    const utcExpires = new Date(0);
    utcExpires.setUTCMilliseconds(tokenSession.expires);
    const utcNow = new Date(Date.now());

     
    //setCount(count + 1);
    //clearTimeout(refreshDisplayTimer);

    return (
        <Container component="main" maxWidth="xs">
            <div>
                <span>Sucesso no Login</span>
                <br />

                <span>USER DETAILS</span>
                <br /><br />
                <span>Role: {tokenSession.role} </span>
                <br /><br />
                <span>AppKey: {tokenSession.claims["appKey"]} </span>
                <br /><br />
                <span>User Id: {tokenSession.claims["userId"]} </span>
                <br /><br />
                <span>FullName: {tokenSession.claims["fullName"]} </span>
                <br /><br />
                <span>LanguageCode: {tokenSession.claims["languageCode"]} </span>
                <br /><br />
                <span>Expira em: {utcExpires.toUTCString()} </span>
                <br /><br />
                <span>Agora: {utcNow.toUTCString()} </span>
                <br /><br /><br />

                Bearer {tokenSession.accessToken}

                <br /><br />
                
              
                <a href='/logoff'><span>Logoff</span></a>
            </div>
        </Container>
    );
}

export { ReturnDefault };