// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import {
    Container,
    makeStyles,
    Typography,
    Box,
    TextField,
    CssBaseline,
    Button
} from '@material-ui/core';
import AppLogo from '../_components/AppLogo';
import { Link } from 'react-router-dom';
import { alertService } from '../_services';
import { identityAuthenticatorService } from '../_services/identity.authenticator.service'

import { useFormik } from 'formik';
import * as Yup from 'yup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(15),
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        paddingBottom: 30
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    localeButton: {
        width: 100,
        height: 30,
        border: 0,
        borderRadius: 0
    }
}));

function SignUp({ history }) {
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const [isSubmitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    //const [locale, setLocale] = useState('pt_br');
    const [languageCode, setLocale] = useState('en_us');

    //const [languageCode, setLocale] = useState('en_us');
    /*
    console.log("State:" + history.location.state);

    if (history.location.state == undefined) {
        console.log("AAAA");
        const [ languageCode, setLocale ] = useState('en_us');
    } else {
        console.log("BBBB");
        const { languageCode } = history.location.state;
    }
    */
    console.log("Idioma:");
    console.log(languageCode);

    const initialValues = {
        email: '',
        languageCode
    };

    //console.log("LOCALE:" + locale);
    //console.log("languageCode:" + languageCode);


    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email(`${t('common.forms.validation.invalidEmail')}`)
            .required(`${t('common.forms.validation.required')}`),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitting(true);
            const { email, fullName } = values;

            identityAuthenticatorService.register({ fullName, email })
                .then(() => {
                    //alertService.success('Registration successful, please check your email for verification instructions', { keepAfterRouteChange: true });
                    history.push('verify-email', { email, languageCode });
                })
                .catch(error => {


                    if (error.message == 'Failed to fetch') {
                        console.log(error);
                        setErrorMessage(t('service_msg.connection_refused'));
                    }
                    else {
                        switch (error.code) {
                            case 'incomplete_password':
                                history.push('verify-email', { email, languageCode });
                                break;
                            case 'incomplete_verification':
                                history.push('verify-email', { email, languageCode });
                                break;
                            case 'incomplete_profile':
                                //history.push('verify-email', { email, locale });
                                break;
                            default:
                                // error.code or error.message
                                alertService.error(t('service_msg.' + error.code));
                                setErrorMessage(t('service_msg.' + error.code));
                        }
                    }
                    
                }).finally(() => {
                    setSubmitting(false);
                });
        },
    });

    const handleLanguageCodeChange = (event, data) => {
        if (data !== null) {
            setLocale(data);
        }
    };

    useEffect(() => {
        i18n.changeLanguage(languageCode);
    }, [i18n, languageCode])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box className={classes.box} border={1} borderColor="#ccc" pr={8} pl={8} pt={5} pb={5}>
                    <Box mb={3}>
                        <AppLogo />
                    </Box>
                    <Typography component="span" variant="subtitle1">
                        {t('account.signUp_label_1')}
                    </Typography>
                    <Typography component="span" variant="subtitle2">
                        {t('account.signUp_label_2')}
                    </Typography>
                    <Typography component="span" variant="caption">
                        {t('account.signUp_label_3')}
                    </Typography>
                    <form className={classes.form} onSubmit={formik.handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="fullName"
                            label={t('account.yourName')}
                            placeholder="Full Name"
                            name="fullName"
                            size="small"
                            value={formik.values.fullName}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.fullName)}
                            helperText={formik.touched.email && formik.errors.fullName}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label={t('account.emailAddress')}
                            placeholder="name@company.com"
                            name="email"
                            size="small"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <Typography variant="body2" color="error">
                            {errorMessage}
                        </Typography>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isSubmitting}
                        >
                            {t('common.forms.nextButton')}
                        </Button>
                        <Typography component="h1" variant="body2">
                            {t('account.signUp_label_4')}
                            <Link to="login" variant="body2">
                                {` ${t('account.signIn')}`}
                            </Link>
                        </Typography>
                    </form>

                    <ToggleButtonGroup
                        value={languageCode}
                        exclusive
                        onChange={handleLanguageCodeChange}
                        aria-label="locale"
                    >
                        <ToggleButton value="en_us" aria-label="english" className={classes.localeButton}>
                            English
                            </ToggleButton>
                        <ToggleButton value="pt_br" aria-label="português" className={classes.localeButton}>
                            Português
                            </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </div>
        </Container>
    );
}

export { SignUp };