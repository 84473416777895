import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { history } from './_helpers/';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_pt_br from "./_translations/pt-br/common.json";
import common_en_us from "./_translations/en-us/common.json";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { WorkpanelAlert } from './_components/WorkpanelAlert';
import { identitySessionControllerService } from './_services/identity.session.controller.service'

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en_us',                              // language to use
    resources: {
        en_us: {
            common: common_en_us               // 'common' is our custom namespace
        },
        pt_br: {
            common: common_pt_br
        },
    },
});

identitySessionControllerService.sessionUserValidate();

console.log("EXECUCAO INDEX");

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <WorkpanelAlert id='default-alert' fade='true' />
      <Router history={history}>
        <App />
      </Router>
    </MuiPickersUtilsProvider>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
