
//import { identityAuthenticatorService } from '../_services/identity.authenticator.service'
import { identitySessionControllerService } from '../_services/identity.session.controller.service'



export const fetchWrapper = {
    get,
    getFile,
    post,
    put,
    putAsFormData,
    postAsFormData,
    delete: _delete,
}

function get(url: string) {
    const requestOptions: any = {
        method: 'GET',
        headers: authHeader(url)
    };
   
    return fetch(url, requestOptions).then(handleResponse);
}

function getFile(url: string) {
    const requestOptions: any = {
        method: 'GET',
        headers: authHeader(url)
    };
    return fetch(url, requestOptions);
}

function post(url: string, body: any) {
    const requestOptions: any = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        credentials: 'include',
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function postAsFormData(url: string, body: FormData) {
    const requestOptions: any = {
        method: 'POST',
        headers: { ...authHeader(url) },
        credentials: 'include',
        body
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function put(url: string, body: any) {
    const requestOptions: any = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function putAsFormData(url: string, body: FormData) {
    const requestOptions: any = {
        method: 'PUT',
        headers: { ...authHeader(url) },
        body: body
    };
    return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url: string) {
    const requestOptions: any = {
        method: 'DELETE',
        headers: authHeader(url)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

// helper functions

function authHeader(url: string) {
    // return auth header with jwt if user is logged in and request is to the api url
    //var user = identityService.userValue;
    var tokenSession = identitySessionControllerService.tokenSessionValue;

    const isLoggedIn = tokenSession && tokenSession.accessToken;

    if (isLoggedIn) {
        return { Authorization: `Bearer ${tokenSession.accessToken}` };

    } else {
        return {};
    }
}

function handleResponse(response: any) { 
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);

        if (!response.ok) {         

            if ([401, 403].includes(response.status) && identitySessionControllerService.tokenSessionValue) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                identitySessionControllerService.logout();
            }

            //const error = (data && data.message) || response.statusText;
            const error = (data) || response.statusText;
            return Promise.reject(error);
        }
        
        return data;
    });
}