import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { Main } from './main';
import { Account } from './account';
import { Ok } from './ok';
import { Off } from './off';
import { PrivateRoute } from './_components/PrivateRoute';
import { identityAuthenticatorService } from './_services/identity.authenticator.service'
import { identitySessionControllerService } from './_services/identity.session.controller.service'

import { useTranslation } from 'react-i18next';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#F6B900',
    },
    secondary: {
      main: "#7E7E7E",
    },
    text: {
      primary: "#7E7E7E",
    },
  },
  typography: {
    fontFamily: [
      // '-apple-system',
      // 'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'none',
      fontWeight: 800
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
      },
    },
  },
});

function App() {

   //identitySessionControllerService.resetInfoMemory();

//    identitySessionControllerService.sessionUserValidate();
    console.log("EXECUCAO APP");

    //identityService.refreshSession();

    const [t, i18n] = useTranslation('common');

    //console.log("oioioi");

  useEffect(() => {
    // redirect to home if already logged in
      const subscription = identitySessionControllerService.sessionUser.subscribe((user) => {
          if (user) {
              
              console.log("testando:");
              console.log(user);
              const { claims: { languageCode } }: any = user;

              console.log("Language Code: " + languageCode);

              if (languageCode) {
                  i18n.changeLanguage(languageCode);
              }
      }
      });

    return () => {
      subscription.unsubscribe();
      };

  }, []);

  return (

    <ThemeProvider theme={theme}>
      <Switch>
        {/* <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> */}

        <PrivateRoute exact path="/" component={Main} />
        <Route path="/account" component={Account} />
        <PrivateRoute path="/ok" component={Ok} />
        <PrivateRoute path="/return" component={Ok} />
        <Route path="/logoff" component={Off} />
        <Redirect from="*" to="/" />

              {/*
              <PrivateRoute exact path="/" component={Task} />
              <PrivateRoute path="/task" component={Task} />
              <Route path="/account" component={Account} />
              <Redirect from="*" to="/" />
              */}

      </Switch>
    </ThemeProvider>
      
  );
}

export default App;
