import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppLogo from '../_components/AppLogo';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { alertService } from '../_services';
import { identityAuthenticatorService } from '../_services/identity.authenticator.service'

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(15)
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        // marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function SetupPassword({ history }) {
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const { email, languageCode, keyAccessPasswordSettingProcess } = history.location.state;
    const [isSubmitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const initialValues = {
        email,
        password: '',
        confirmPassword: ''
    };

    console.log("Chave Senha: " + keyAccessPasswordSettingProcess)

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, `${t('common.forms.validation.minCharacter', { min: 8 })}`)
            .max(50, `${t('common.forms.validation.maxCharacter', { max: 50 })}`)
            .required(`${t('common.forms.validation.required')}`),
        confirmPassword: Yup.string()
            .required(`${t('common.forms.validation.required')}`)
            .oneOf([Yup.ref('password')], `${t('common.forms.validation.passwordMismatch')}`)
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitting(true);
            values.keyAccessPasswordSettingProcess = keyAccessPasswordSettingProcess;
            identityAuthenticatorService.createPassword(values)
                .then(() => {
                    Console.log("Senha definida");
                    //history.push('setup-profile', { email, languageCode });
                    //history.push('login', { locale });
                })
                .catch(error => {

                    if (error.message == 'Failed to fetch') {
                        console.log(error);
                        setErrorMessage(t('service_msg.connection_refused'));
                    }
                    else {
                        switch (error.code) {
                            case 'incomplete_profile':
                                history.push('setup-profile', { email, locale });
                                break;
                            case 'incomplete_verification':
                                history.push('verify-email', { email, locale });
                                break;
                            default:
                                console.log(error.message);
                                alertService.error(t('service_msg.' + error.code));
                                setErrorMessage(t('service_msg.' + error.code));
                        }
                    }

                }).finally(() => {
                    setSubmitting(false);
                });
        },
    });

    useEffect(() => {
        i18n.changeLanguage(languageCode);
    }, [i18n, languageCode])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box className={classes.box} pr={10} pl={10} pt={5} pb={5}>
                    <Box mb={3}>
                        <AppLogo />
                    </Box>

                    <Typography variant="subtitle1">
                        {t('account.setupPassword_label_1')}
                    </Typography>
                    <form className={classes.form} onSubmit={formik.handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="password"
                            label={t('account.password')}
                            name="password"
                            size="small"
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="confirmPassword"
                            label={t('account.confirmPassword')}
                            name="confirmPassword"
                            size="small"
                            type="password"
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        />
                        <Typography variant="body2" color="error">
                            {errorMessage}
                        </Typography>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            className={classes.submit}
                            disabled={isSubmitting}
                        >
                            {t('account.startHere')}
                        </Button>
                    </form>
                </Box>
            </div>
        </Container>
    );
}

export { SetupPassword };