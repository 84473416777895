import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppLogo from '../_components/AppLogo';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { alertService, configDynamicService } from '../_services'

import { identityAuthenticatorService } from '../_services/identity.authenticator.service'

import { Backdrop, Divider, CircularProgress } from '@material-ui/core';
import { GoogleLogin } from 'react-google-login';
import  FacebookLogin  from 'react-facebook-login';
import { config } from '../config';
import facebook from '../assets/facebook.svg';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useTranslation } from 'react-i18next';


//import './padrao.scss';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(15)
    },
    appLogo: {
        textAlign:'center',
        width: '150%'
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    googleButton: {
        width: '140px',
        height: '41px',
        fontSize: 14,
    },
    facebookButton: {
        width: '140px',
        height: '41px',
        fontSize: 14,
        display: 'inline-flex',
        alignItems: 'center',
        textAlign: 'center',
        color: theme.palette.text.primary,
        padding: 0,
        cursor: 'pointer',
        backgroundColor: '#fff',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 2,
        borderColor: '#00000000',
        boxShadow: 'rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    localeButton: {
        width: 100,
        height: 30,
        border: 0,
        borderRadius: 0,
        marginTop:30
    }
}));

function SignIn({ history }) {

    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const [isSubmitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [languageCode, setLocale] = useState('en_us');


    const initialValues = {
        email: '',
        password: ''
    };

    const validationSchema = Yup.object({
        email: Yup.string().required('Required'),
        password: Yup.string().required('Required'),
    });

  

    const formik = useFormik({

        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitting(true);
            setErrorMessage('');
            const { email, password } = values;
            identityAuthenticatorService.login(email, password)
                .then(() => {

                    console.log("retorno");
                    history.push('/return');

                    //history.push('/Task');
                })
                .catch(error => {
                    if (error.message == 'Failed to fetch') {
                        console.log(error);
                        setErrorMessage(t('service_msg.connection_refused'));
                    }
                    else {
                        switch (error.code) {
                            case 'incomplete_password':
                                //history.push('verify-email', { email, locale });
                                console.log(error.message);
                                setErrorMessage(t('service_msg.invalid_credentials'));
                                break;
                            case 'incomplete_profile':
                                history.push('setup-profile', { email, languageCode });
                                break;
                            //case 'incomplete_verification':
                            //    history.push('verify-email', { email, locale });
                            //    break;
                            default:
                            console.log(error.message);
                            setErrorMessage(t('service_msg.' + error.code));
                        }
                    }

                }).finally(() => {
                    setSubmitting(false);
                });
        },
    });

    const googleResponse = (response) => {
        console.log("TESTE GOO STAR");
        console.log(response);
        setSubmitting(true);
        const { tokenId } = response;
        identityAuthenticatorService.loginWithGoogle({ tokenId })
            .then(() => {
                console.log("retorno");
                history.push('/return');
                //history.push('/Task');
            })
            .catch(error => {
                console.log(error);
            }).finally(() => {
                setSubmitting(false);
            });
    }

    const facebookResponse = (response) => {
        setSubmitting(true);
        identityAuthenticatorService.loginWithFacebook(response)
            .then(() => {
                console.log("retorno");
                history.push('/return');
                //history.push('/Task');
            })
            .catch(error => {
                console.log(error);
            }).finally(() => {
                setSubmitting(false);
            });;
    }
    const onFailure = (error) => {
        console.error('onFailure', error);
    }

    const handleLocaleChange = (event, data) => {
        if (data !== null) {
            setLocale(data);
        }
    };


    //console.log('locale::: '+ locale)
    useEffect(() => {
        i18n.changeLanguage(languageCode);
        console.log("Idioma: " + languageCode)
    }, [i18n, languageCode])


        return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box className={classes.box} border={1} borderColor="#ccc" pr={10} pl={10} pt={5} pb={5}>
                    <Box className={classes.appLogo} mb={3}>
                        <AppLogo/>
                    </Box>
                    <Typography component="h4" variant="subtitle1">
                        {t('account.signIn_label_1')}
                    </Typography>
                    <form className={classes.form} onSubmit={formik.handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label={t('account.emailAddress')}
                            name="email"
                            size="small"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="password"
                            label={t('account.password')}
                            type="password"
                            id="password"
                            size="small"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        <Typography variant="body2" color="error">
                            {errorMessage}
                        </Typography>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isSubmitting}
                        >
                            {t('account.logIn')}
                        </Button>
                        <Typography component="h1" variant="body2">
                            {t('account.signIn_dontHaveAccount_SingUp')}<span>&nbsp;&nbsp;</span>



                            <Link to={{
                                pathname: 'register',
                                state: {
                                    languageCode: languageCode
                                }
                            }}>{t('account.signUp')}</Link>
                            

                        </Typography>
                    </form>
                    <Divider />

                    <Typography variant="body2" style={{ padding: '20px 0 10px 0' }}>
                        {t('account.logInWithSocialNetWork')}
                    </Typography>

                    <div style={{ display: 'inline-flex', margin: 2 }}>
                        {/* facebook login */}
                        <FacebookLogin
                            cssClass={classes.facebookButton}
                            icon={
                                <img style={{ padding: 10, marginRight: 10 }} src={facebook} alt='facebook' />
                            }
                            appId={config.FACEBOOK_CLIENT_ID}
                            autoLoad={false}
                            textButton='Facebook'
                            fields="name,email,picture"
                            callback={facebookResponse} />
                        <div style={{ display: 'inline', width: '8px' }}></div>
                        {/* google login */}
                        <GoogleLogin
                            className={classes.googleButton}
                            clientId={config.GOOGLE_CLIENT_ID}
                            buttonText="Google"
                            isSignedIn={false}
                            onSuccess={googleResponse}
                            onFailure={onFailure}
                        />
                    </div>

                    <ToggleButtonGroup
                        value={languageCode}
                        exclusive
                        onChange={handleLocaleChange}
                        aria-label="locale"
                    >
                        <ToggleButton value="en_us" aria-label="english" className={classes.localeButton}>
                            English
                            </ToggleButton>
                        <ToggleButton value="pt_br" aria-label="portugu�s" className={classes.localeButton}>
                            Portugu&#234;s
                            </ToggleButton>
                    </ToggleButtonGroup>

                </Box>
            </div>
            <Backdrop className={classes.backdrop} open={isSubmitting}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container >
    );
}

export { SignIn };