import React, { useEffect, useState } from 'react';
import { alertService, configDynamicService } from '../_services'
//import { identityAuthenticatorService } from '../_services/identity.authenticator.service'
import { identitySessionControllerService } from '../_services/identity.session.controller.service'


import Container from '@material-ui/core/Container';


function Logout ({ history }) {

    //identitySessionControllerService.resetInfoMemory();
    identitySessionControllerService.logoutForced();
    //identitySessionControllerService.logout();

    return (
        <Container component="main" maxWidth="xs">
            <div>
            <span>Preparar pra sair</span>
            </div>
        </Container>
    );
}

export { Logout };