import { Subject } from 'rxjs';
//import { identitySessionControllerService } from '../_services/identity.session.controller.service'


const alertSubject = new Subject();
const defaultId = 'default-alert';

export const configDynamicService = {
    project_actual,
    app_key_hub,
    url_return_after_login
};


function project_actual(): string {

    var key = null;
    var domain = window.location.hostname;

    switch (domain) {
        case "login.workpanel.app":
            key = "workpanel";
            break;
        case "login.getalert.app":
            key = "getalert";
            break;
        case "login.dataproject.app":
            key = "dataproject";
            break;
        case "login.proximaentrega.com":
            key = "proximaentrega";
            break;
        case "localhost":
            key = "workpanel";
            break;
        default:
            key = null;
    }
    return key;
}

function app_key_hub(): string {

    var key = "";
    
    switch (project_actual()) {
        case "workpanel":
            key = "workpanel";
            break;
        case "getalert":
            key = "getalert";
            break;
        case "dataproject":
            key = "dataproject";
            break;
        case "proximaentrega":
            key = "proximaentrega";
            break;
        default:
            key = "";
    }

    return key;
}


function url_return_after_login(key): string {
    var urlReturn = "";

    //var key = identitySessionControllerService.tokenSessionValue.claims["connectionKey"];

    switch (project_actual()) {
        case "workpanel":
            urlReturn = "https://workpanel.app/Auth/TokenRegister/" + key;
            //urlReturn = "http://localhost:3001/Auth/TokenRegister/" + key;
            break;
        case "getalert":
            urlReturn = "https://getalert.app/Auth/TokenRegister/" + key;
            break;
        case "dataproject":
            urlReturn = "https://dataproject.app/Auth/TokenRegister/" + key;
            break;
        case "proximaentrega":
            urlReturn = "https://proximaentrega.app/Auth/TokenRegister/" + key;
            break;
        
        default:
            urlReturn = "";
    }

    return urlReturn;
}






function titleApp(key): string {
    var title = "";

    switch (project_actual()) {
        case "workpanel":
            title = "WorkPanel";
            break;
        case "getalert":
            title = "GetAlert";
            break;
        case "dataproject":
            title = "DataProject";
            break;
        case "proximaentrega":
            title = "Pr�ximaEntrega";
            break;

        default:
            title = "";
    }

    return title;
}


