import { Link } from '@material-ui/core';
import React from 'react';

import { configDynamicService } from '../_services/config.dynamic.service'

import logo_workpanel from '../assets/logo_workpanel.svg';
import logo_getalert from '../assets/logo_getalert.svg';
import logo_dataproject from '../assets/logo_dataproject.svg';
import logo_proximaentrega from '../assets/logo_proximaentrega.svg';

import AppVersion from './AppVersion';


var logo;

switch (configDynamicService.project_actual()) {
    case "workpanel":
        logo = logo_workpanel;
        break;
    case "getalert":
        logo = logo_getalert;
        break;
    case "dataproject":
        logo = logo_dataproject;
        break;
    case "proximaentrega":
        logo = logo_proximaentrega;
        break;
    default:
        logo = logo_workpanel;
}

export default function AppLogo() {
    return (
        <>
            <Link href="/">
                <img width='100%' src={logo} />
            </Link>
            <AppVersion />
        </>
    );
}
