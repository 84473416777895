import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { identitySessionControllerService } from '../_services/identity.session.controller.service'


function PrivateRoute({ component: Component, ...rest }) {
    return (
        <Route {...rest} render={props => {
            const tokenSession = identitySessionControllerService.tokenSessionValue;
            if (!tokenSession || tokenSession.role == "guest") {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />
            }

            // authorized so return component
            return <Component {...props} />
        }} />
    );
}

export { PrivateRoute };