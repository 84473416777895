import { BehaviorSubject } from 'rxjs';
import { fetchWrapper, history } from '../_helpers';
import { config } from '../config';


import { configDynamicService } from '../_services/config.dynamic.service'
import { identitySessionControllerService } from '../_services/identity.session.controller.service'



const baseUrl = `${config.IDENTITY_SERVICE_BASEURL}`;
const appKey = configDynamicService.app_key_hub(); //`${config.HUB_APP_KEY}`;
const tokenName = "workpanel-token";

const tokenSessionName = "hub-session";


type Nullable<T> = T | null;


interface TokenSessionModel {
    accessToken: string | null,
    refreshToken: string,
    expires: number,
    id: string,
    role: string,
    claims: {}
}

interface ValidateEmailResponseModel {
    accessToken: string | null,
    refreshToken: string,
    expires: number,
    id: string,
    role: string,
    claims: {}
}


function getSession(): TokenSessionModel {
    return JSON.parse(localStorage.getItem(tokenSessionName));
}



const tokenSessionSubject = new BehaviorSubject<Nullable<TokenSessionModel>>(identitySessionControllerService.getSession());

export const identityAuthenticatorService = {
    login,
    loginWithGoogle,
    loginWithFacebook,
    //logout,
    //resetInfoMemory,
    //refreshToken,
    register,
    verifyEmail,
    resendCode,
    createProfile,
    createPassword,
    //getUsers,
    //user: userSubject.asObservable(),
    sessionUser: tokenSessionSubject.asObservable(),

    //get userValue() { return userSubject.value },
    get tokenSessionValue() { return tokenSessionSubject.value }
};



function login(email: string, password: string) {
    console.log("AppKey: " + appKey)
    console.log("Email: " + email)
    console.log("Password: " + password)

    return fetchWrapper.post(`${baseUrl}/session/session-sign-in-with-email`, { appKey, email, password })
        .then((tokenSession: TokenSessionModel) => {
            // publish user to subscribers and start timer to refresh token
            identitySessionControllerService.storeTokenSession(tokenSession);
            identitySessionControllerService.tokenSessionSubject.next(tokenSession);  /// AETEN��O
            identitySessionControllerService.startSessionUserTimer();

            const utcExpires = new Date(0);
            utcExpires.setUTCMilliseconds(tokenSession.expires);
            const utcNow = new Date(Date.now());

            console.log("NOVO UTC EXPIRA EM:" + utcExpires.toUTCString());
            console.log("UTC AGORA:" + utcNow.toUTCString());
            console.log("TOKEN AUTH:" + tokenSession.accessToken);
            console.log(tokenSession);


            return tokenSession;
        });
}

function loginWithGoogle(params) {

    return fetchWrapper.post(`${baseUrl}/session/google/authenticate`, params)
        .then((tokenSession: TokenSessionModel) => {
            // publish user to subscribers and start timer to refresh token
            identitySessionControllerService.storeTokenSession(tokenSession);
            tokenSessionSubject.next(tokenSession);
            identitySessionControllerService.startSessionUserTimer();

            const utcExpires = new Date(0);
            utcExpires.setUTCMilliseconds(tokenSession.expires);
            const utcNow = new Date(Date.now());

            console.log("NOVO UTC EXPIRA EM:" + utcExpires.toUTCString());
            console.log("UTC AGORA:" + utcNow.toUTCString());
            console.log(tokenSession);


            return tokenSession;
        });/*
        .then((user: IdentityModel) => {
            // publish user to subscribers and start timer to refresh token
            store(user);
            userSubject.next(user);
            startRefreshTokenTimer();
            return user;
        });*/
}

function loginWithFacebook(params) {

    params.appKey = appKey;

    return fetchWrapper.post(`${baseUrl}/session/facebook/authenticate`, { params })
        .then((tokenSession: TokenSessionModel) => {
            // publish user to subscribers and start timer to refresh token
            identitySessionControllerService.storeTokenSession(tokenSession);
            tokenSessionSubject.next(tokenSession);
            identitySessionControllerService.startSessionUserTimer();

            const utcExpires = new Date(0);
            utcExpires.setUTCMilliseconds(tokenSession.expires);
            const utcNow = new Date(Date.now());

            console.log("NOVO UTC EXPIRA EM:" + utcExpires.toUTCString());
            console.log("UTC AGORA:" + utcNow.toUTCString());
            console.log(tokenSession);


            return tokenSession;
        });/*
        .then((user: IdentityModel) => {
            // publish user to subscribers and start timer to refresh token
            store(user);
            userSubject.next(user);
            startRefreshTokenTimer();
            return user;
        });*/
}



/*
function logout() {
    // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
    
    var sessionAccessId = tokenSessionSubject.value.claims["sessionAccessId"];
    var sessionUserId = tokenSessionSubject.value.claims["sessionUserId"];

    console.log("Dados para logout");
    console.log(sessionAccessId);
    console.log(sessionUserId);


    /// Interrompe todos os timers
    identitySessionControllerService.stopRefreshSessionTokenTimer();

    fetchWrapper.post(`${baseUrl}/session-sign-out`, { appKeyHUB, sessionAccessId, sessionUserId })
        .then((tokenSession: TokenSessionModel) => {
            // publish user to subscribers and start timer to refresh token

            identitySessionControllerService.storeTokenSession(tokenSession);
            tokenSessionSubject.next(tokenSession);

            identitySessionControllerService.startSessionUserTimer();

            /*const utcExpires = new Date(0);
            utcExpires.setUTCMilliseconds(tokenSession.expires);
            const utcNow = new Date(Date.now());

            console.log("Novo Token ao fazer Logout:");
            console.log("NOVO UTC EXPIRA EM:" + utcExpires.toUTCString());
            console.log("UTC AGORA:" + utcNow.toUTCString());
            console.log(tokenSession);*/

            /*return tokenSession;
        });

    //stopRefreshSessionTokenTimer();
    //userSubject.next(null);

    //storeTokenSession(null);
    //tokenSessionSubject.next(null);

    clear();
    history.push('/account/login');

}

*/

/*
function logoutForced() {
    // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page

    if (tokenSessionSubject != null) {
        var sessionAccessId = tokenSessionSubject.value.claims["sessionAccessId"];
        var sessionUserId = tokenSessionSubject.value.claims["sessionUserId"];

        fetchWrapper.post(`${baseUrl}/session-sign-out`, { appKeyHUB, sessionAccessId, sessionUserId });
    }

    stopRefreshSessionTokenTimer();

    // Apagar os dados de sess�o de maneira for�ada
    resetInfoMemory();


    clear();
    history.push('/account/login');
}


function resetInfoMemory() {

    /// Limpa Mem�ria da Sess�o
    storeTokenSession(null);
    tokenSessionSubject.next(null);

}
*/


/*
function refreshToken() {

    console.log("Refresh Iniciado");

    const { refreshToken } = userSubject?.value;

    var user = identityService.userValue;

    console.log("Params REfresh:");
    console.log(user);
    
    //return fetchWrapper.post(`${baseUrl}/access-tokens/${refreshToken}/refresh`, { appKeyHUB, user }, false)

    return fetchWrapper.post(`${baseUrl}/access-tokens/refresh`, { appKeyHUB, user })
        .then((user: IdentityModel) => {
            // publish user to subscribers and start timer to refresh token

            store(user);
            userSubject.next(user);
            startRefreshTokenTimer();


            const utcExpires = new Date(0);
            utcExpires.setUTCMilliseconds(user.expires);
            const utcNow = new Date(Date.now());

            console.log("NOVO UTC EXPIRA EM:" + utcExpires.toUTCString());
            console.log("UTC AGORA:" + utcNow.toUTCString());

            console.log("Refresh Efetuado");
            return user;
        }).catch(error => {
            logout();
        });
}
*/


function register(params: any) {
    params.appKey = appKey;

    var b = fetchWrapper.post(`${baseUrl}/session/session-sign-up-with-email`, params);
    return b;
}

function verifyEmail(params: any) {
    params.appKey = appKey;
    //console.log("-- App Key: " + params.appKey);
    //console.log("-- email: " + params.email);
    //console.log("-- validateCode: " + params.validateCode);
    var b = fetchWrapper.post(`${baseUrl}/User/user-validate-email-with-short-code`, params);/*
        .then((validateEmailResponse: ValidateEmailResponseModel) => {
        // publish user to subscribers and start timer to refresh token
        identitySessionControllerService.storeTokenSession(tokenSession);
        tokenSessionSubject.next(tokenSession);
        identitySessionControllerService.startSessionUserTimer();

        const utcExpires = new Date(0);
        utcExpires.setUTCMilliseconds(tokenSession.expires);
        const utcNow = new Date(Date.now());

        console.log("NOVO UTC EXPIRA EM:" + utcExpires.toUTCString());
        console.log("UTC AGORA:" + utcNow.toUTCString());
        console.log(tokenSession);


        return tokenSession;
    });*/

    //return fetchWrapper.post(`${baseUrl}/session/google/authenticate`, params)
        
         
    //console.log("Retorno Verificao:" + b.KeyAccessPasswordSettingProcess);
    return b;
}

function resendCode(email: string) {
    return fetchWrapper.get(`${baseUrl}/resend-code?appKey=${appKey}&email=${email}`);
}

function createProfile(params: any) {
    params.appKey = appKey;
    return fetchWrapper.put(`${baseUrl}/create-profile`, params);
}

function createPassword(params: any) {
    params.appKey = appKey;

    var userId = params.userId;
    var password = params.password;
    var keyAccessSettingProcess = params.KeyAccessSettingProces;

    console.log("Parametros SEtPassword:");
    console.log(params);
    console.log("UserId:" + userId);
    console.log("Password:" + password);

    return fetchWrapper.put(`${baseUrl}/user/user-password-set`, { userId, password, keyAccessSettingProcess });
}









function clear() {
    localStorage.setItem(tokenName, null);
}







// helper functions

let refreshTokenTimeout: any;

/*
function checkSession() {
    // parse json object from base64 encoded jwt token
    const { expires } = userSubject?.value;
    // const token = userSubject?.value?.accessToken ?? '.';
    // const jwtToken = JSON.parse(atob(token.split('.')[1]));
    // set a timeout to refresh the token a minute before it expires
    // const expires = new Date(jwtToken.exp * 1000);
    const expiry = new Date(expires);
    const timeout = expiry.getTime() - Date.now() - 1000;

    refreshTokenTimeout = setTimeout(refreshToken, timeout);
}
*/



/*
 * 
 * let testeTimeout: any;

function startRefreshTokenTimer() {
    stopRefreshTokenTimer();
    if (userSubject?.value) {
        // parse json object from base64 encoded jwt token
        const { expires } = userSubject?.value;
        // const token = userSubject?.value?.accessToken ?? '.';
        // const jwtToken = JSON.parse(atob(token.split('.')[1]));
        // set a timeout to refresh the token a minute before it expires
        // const expires = new Date(jwtToken.exp * 1000);
        const expiry = new Date(expires);
        const timeout = expiry.getTime() - Date.now() - 1000;

        refreshTokenTimeout = setTimeout(refreshToken, timeout);
        //refreshTokenTimeout = setTimeout(refreshToken, 10000);
    }

    testeTimeout = setTimeout(teste, 1000);
}

function teste() {
    const utcNow = new Date(Date.now());
    console.log(utcNow.toUTCString());

    testeTimeout = setTimeout(teste, 1000);
}

function stopRefreshTokenTimer() {
    clearTimeout(refreshTokenTimeout);
    clearTimeout(testeTimeout);
}

function store(user: IdentityModel) {
    localStorage.setItem(tokenName, JSON.stringify(user));
}
function get(): IdentityModel {
    return JSON.parse(localStorage.getItem(tokenName));
}




*/
