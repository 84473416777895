import React, { useEffect, useState } from 'react';
import { alertService } from '../_services'
import { fetchWrapper, history } from '../_helpers';

import Container from '@material-ui/core/Container';
//import { identitySessionControllerService } from '../_services/identity.session.controller.service'


function Home({ history }) {

    //const tokenSession = identitySessionControllerService.tokenSessionValue;

    console.log("TESTE HOME");

    //console.log("retorno cookie:");
    //console.log(tokenSession);

    /*
    fetchWrapper.get("").then((response: string) => {
        //console.log(response.toString());
    });*/

    /*
        const requestOptions: any = {
            method: 'GET'
        };
        */
    /*
    var b = fetch("https://localhost:44362/testandoGet", requestOptions).then((response) => {
        console.log(response.text());
    });
    */



    useEffect(() => {
        //window.location.href = dynamicConfigService.GetUrlDefaultAfterLogin();
    }, []);


    /*
    return (
        <Container component="main" maxWidth="xs">
            <div>
                <span>TELA INICIAL COM CONEXAO EFETUADA</span>
                <br/>
                <span>Role: {tokenSession.role} </span>
                <br />
                <span>User Id: {tokenSession.claims["userId"]} </span>
                <br />
                <span>FullName: {tokenSession.claims["fullName"]} </span>
            </div>
            <br/><br/>
            <a onClick={() => {
                        identitySessionControllerService.logout();
                    }}>Logoff</a>

        </Container>
    );
    */



    return (
        <Container component="main" maxWidth="xs">
            <div>
                <span>EITA</span>
            </div>

        </Container>
    );



}

export { Home };