import React, { useEffect, useState } from 'react';
import { alertService, configDynamicService } from '../_services'

import Container from '@material-ui/core/Container';
import * as rdd from 'react-device-detect';

console.log("teste");
console.log(rdd.deviceDetect);

function Teste({ history }) {

    useEffect(() => {
        //window.location.href = dynamicConfigService.GetUrlDefaultAfterLogin();
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <div>
                <span>TESTE</span>
                <br /><br />
                <span>isMobile: {rdd.isMobile.toString()}</span><br />
                <span>isMobileOnly: {rdd.isMobileOnly.toString()}</span><br />
                <span>isTablet: {rdd.isTablet.toString()}</span><br />
                <span>isBrowser: {rdd.isBrowser.toString()}</span><br />
                <span>isDesktop: {rdd.isDesktop.toString()}</span><br />
                <span>isSmartTV: {rdd.isSmartTV.toString()}</span><br />
                <span>isWearable: {rdd.isWearable.toString()}</span><br />
                <span>isConsole: {rdd.isConsole.toString()}</span><br />
                <span>isAndroid: {rdd.isAndroid.toString()}</span><br />
                <span>isWinPhone: {rdd.isWinPhone.toString()}</span><br />
                <span>isIOS: {rdd.isIOS.toString()}</span><br />
                <span>isChrome: {rdd.isChrome.toString()}</span><br />
                <span>isFirefox: {rdd.isFirefox.toString()}</span><br />
                <span>isSafari: {rdd.isSafari.toString()}</span><br />
                <span>isOpera: {rdd.isOpera.toString()}</span><br />
                <span>isIE: {rdd.isIE.toString()}</span><br />
                <span>isEdge: {rdd.isEdge.toString()}</span><br />
                <span>isYandex: {rdd.isYandex.toString()}</span><br />
                <span>isChromium: {rdd.isChromium.toString()}</span><br />
                <span>isMobileSafari: {rdd.isMobileSafari.toString()}</span><br />
                <span>isSamsungBrowser: {rdd.isSamsungBrowser.toString()}</span><br />
                <span>osVersion: {rdd.osVersion.toString()}</span><br />
                <span>osName: {rdd.osName.toString()}</span><br />
                <span>fullBrowserVersion: {rdd.fullBrowserVersion.toString()}</span><br />
                <span>browserVersion: {rdd.browserVersion.toString()}</span><br />
                <span>browserName: {rdd.browserName.toString()}</span><br />
                <span>mobileVendor: {rdd.mobileVendor.toString()}</span><br />
                <span>mobileModel: {rdd.mobileModel.toString()}</span><br />
                <span>engineName: {rdd.engineName.toString()}</span><br />
                <span>engineVersion: {rdd.engineVersion.toString()}</span><br />
                <span>getUA: {rdd.getUA.toString()}</span><br />
                <span>deviceType: {rdd.deviceType.toString()}</span><br />
                <span>isIOS13: {rdd.isIOS13.toString()}</span><br />
                <span>isIPhone13: {rdd.isIPhone13.toString()}</span><br />
                <span>isIPad13: {rdd.isIPad13.toString()}</span><br />
                <span>isIPod13: {rdd.isIPod13.toString()}</span><br />
                <span>isElectron: {rdd.isElectron.toString()}</span><br />
                <span>isEdgeChromium: {rdd.isEdgeChromium.toString()}</span><br />
                <span>isLegacyEdge: {rdd.isLegacyEdge.toString()}</span><br />
                <span>isWindows: {rdd.isWindows.toString()}</span><br />
                <span>isMacOs: {rdd.isMacOs.toString()}</span><br />
                <span>deviceDetect: {rdd.deviceDetect.toString()}</span><br />
                <span>OsTypes: {rdd.OsTypes.toString()}</span><br />
                <span>BrowserTypes: {rdd.BrowserTypes.toString()}</span><br />
                <br /><br /><br />
                <span>deviceDetect: {rdd.deviceDetect}</span><br />
            </div>
        </Container>
    );
}

export { Teste };