
export const config = {

    IDENTITY_SERVICE_BASEURL: 'https://localhost:44367',//'http://hub-service-account.azurewebsites.net',
    HUB_APP_KEY: 'abobrinha',
    TASK_SERVICE_BASEURL: 'https://workpanel-task-service.azurewebsites.net',
    FACEBOOK_CLIENT_ID: '2560051627637167',
    GOOGLE_CLIENT_ID: '280955126272-ljo7o004jlcdttfqkjch13hbn72lbg5h.apps.googleusercontent.com'

};

/*
https://hub-identity-service.azurewebsites.net


dev
export const config = {

    IDENTITY_SERVICE_BASEURL: 'https://localhost:44362',//'https://hub-identity-service.azurewebsites.net', //'https://localhost:44362',
    HUB_APP_KEY: 'abobrinha',
    TASK_SERVICE_BASEURL: 'https://localhost:44360', //'https://workpanel-task-service.azurewebsites.net',
    FACEBOOK_CLIENT_ID: '2560051627637167',
    GOOGLE_CLIENT_ID: '280955126272-ljo7o004jlcdttfqkjch13hbn72lbg5h.apps.googleusercontent.com'

};

prod

export const config = {

    IDENTITY_SERVICE_BASEURL: 'https://hub-identity-service.azurewebsites.net',
    HUB_APP_KEY: 'abobrinha',
    TASK_SERVICE_BASEURL: 'https://workpanel-task-service.azurewebsites.net',
    FACEBOOK_CLIENT_ID: '2560051627637167',
    GOOGLE_CLIENT_ID: '280955126272-ljo7o004jlcdttfqkjch13hbn72lbg5h.apps.googleusercontent.com'

};


*/