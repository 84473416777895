import React from 'react';
import { Route, Switch } from 'react-router-dom';

// import { accountService } from '@/_services';

import { SignIn } from './SignIn';
import { SignUp } from './SignUp';
import { VerifyEmail } from './VerifyEmail';
import { SetupPassword } from './SetupPassword';
import { SetupProfile } from './SetupProfile';

import { Reset } from './Reset';
import { Teste } from './Teste';



function Account({ history, match }) {
    const { path } = match;

    // useEffect(() => {
    //     // redirect to home if already logged in
    //     if (accountService.userValue) {
    //         history.push('/');
    //     }
    // }, []);

    return (
        <Switch>
            
            {/*<Route path={`${path}`} component={Home} />*/}
            <Route path={`${path}/login`} component={SignIn} />
            <Route path={`${path}/register`} component={SignUp} />
            <Route path={`${path}/verify-email`} component={VerifyEmail} />
            <Route path={`${path}/setup-password`} component={SetupPassword} />
            <Route path={`${path}/setup-profile`} component={SetupProfile} />

            <Route path={`${path}/teste`} component={Teste} />
            <Route path={`${path}/reset`} component={Reset} />
        </Switch>
    );
}

export { Account };