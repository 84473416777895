import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppLogo from '../_components/AppLogo';
import { alertService } from '../_services';
import { identityAuthenticatorService } from '../_services/identity.authenticator.service'

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Backdrop, CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(15),
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    title: {
        margin: theme.spacing(2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(0),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),

    },
    sendLink: {
        fontWeight: "bold"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

function VerifyEmail({ history }: any) {
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const { email, languageCode } = history.location.state;
    const [isSubmitting, setSubmitting] = useState(false);

    const [errorState, setErrorState] = useState({
        error: false,
        text: ''
    });


    console.log("-- Email: " + email);

    const resendCode = (e) => {
        e.preventDefault();
        identityAuthenticatorService.resendCode(email)//;
        alertService.success(t('account.verifyEmail_label_2', { email }), { keepAfterRouteChange: true })
    };
            //alertService.success('Novo c�digo enviado novamente por email', { keepAfterRouteChange: true })

    const handleTextChange = (e: any) => {
        const validateCode: string = e.target.value;
        if (validateCode.length === 5) {
            //alertService.success('Registration successful, please check your email for verification instructions', { keepAfterRouteChange: true });
            // setErrorState({ error: true, text: "Invalid Code" });
            setSubmitting(true);
            identityAuthenticatorService.verifyEmail({ email, validateCode }).then(data => {

                var userId = data.keyAccessPasswordSettingProcess;
                var password = data.keyAccessPasswordSettingProcess;
                var keyAccessPasswordSettingProcess = data.keyAccessPasswordSettingProcess;

                /*
                        public Guid UserId { get; set; }
        public string Password { get; set; }
        public string KeyAccessSettingProcess { get; set; }*/
                /*console.log("retorno KeyPassword:");
                console.log(data.keyAccessPasswordSettingProcess);
                console.log("retorno UserId:");
                console.log(data.userId);
                console.log("retorn Message:");
                console.log(data.messageReturn);*/

                //history.push('setup-profile', { email, locale });
                history.push('setup-password', { email, userId, languageCode, keyAccessPasswordSettingProcess });

            }, (error) => {
                setErrorState({ error: true, text: t('service_msg.' + error.code) });
            })
                .finally(() => setSubmitting(false));

        }
        if (validateCode.length === 0) {
            setErrorState({ error: false, text: '' });
        }
    }

    useEffect(() => {
        i18n.changeLanguage(languageCode);
    }, [i18n, languageCode])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box className={classes.box} pr={10} pl={10} pt={5} pb={5}>
                    <Box mb={3}>
                        <AppLogo />
                    </Box>
                    <Typography component="span" variant="subtitle1" className={classes.title}>
                        {t('account.verifyEmail_label_1')}
                    </Typography>
                    <Typography component="span" variant="caption">
                        {t('account.verifyEmail_label_2', { email })}
                    </Typography>
                    <form className={classes.form}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="code"
                            label={t('account.verificationCode')}
                            placeholder={t('account.verificationCode')}
                            name="code"
                            size="small"
                            error={errorState.error}
                            helperText={errorState.text}
                            inputProps={{
                                maxLength: 5,
                            }}
                            onChange={handleTextChange}
                        />
                        <Typography className={classes.submit} >
                            <Link
                                onClick={resendCode}
                                href="#"
                                variant="body2" color="secondary" className={classes.sendLink}>
                                {t('account.sendCodeAgain')}
                            </Link>
                        </Typography>

                        <Typography component="h1" variant="body2">
                            {t('account.verifyEmail_label_3')}
                        </Typography>
                    </form>
                </Box>
            </div>
            <Backdrop className={classes.backdrop} open={isSubmitting}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}

export { VerifyEmail };
