import React from 'react';
import { Route, Switch } from 'react-router-dom';


import { ReturnDefault } from './ReturnDefault';

function Ok({ history, match }: any) {
    const { path } = match;
    let background = history.location.state && history.location.state.background;


    return (
        <Switch>
            <Route path={`${path}`} component={ReturnDefault} />
        </Switch>
    );
}

export { Ok };
