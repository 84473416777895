import { BehaviorSubject } from 'rxjs';
import { fetchWrapper, history } from '../_helpers';
import { config } from '../config';

//import { identityService, dynamicConfigService } from '../_services'
import { configDynamicService } from '../_services/config.dynamic.service'

//import { identityAuthenticatorService } from '../_services/identity.authenticator.service'


//var t = dynamicConfigService.GetAppKeyHub();

const baseUrl = `${config.IDENTITY_SERVICE_BASEURL}`;
const appKey = configDynamicService.app_key_hub(); //dynamicConfigService.GetAppKeyHub(); //`${config.HUB_APP_KEY}`;
const tokenName = "workpanel-token";

const tokenSessionName = "hub-session";

console.log("app key:" + appKey)

type Nullable<T> = T | null;


interface TokenSessionModel {
    accessToken: string | null,
    refreshToken: string,
    expires: number,
    id: string,
    role: string,
    claims: {}
}

const tokenSessionSubject = new BehaviorSubject<Nullable<TokenSessionModel>>(getSession());

export const identitySessionControllerService = {
    logout,
    logoutForced,
    resetInfoMemory,
    //refreshToken,
    startSessionUserTimer,
    sessionUserValidate,
    storeTokenSession,
    tokenSessionSubject,
    getSession,
    //user: userSubject.asObservable(),
    sessionUser: tokenSessionSubject.asObservable(),

    //get userValue() { return userSubject.value },
    get tokenSessionValue() { return tokenSessionSubject.value }
};



function sessionUserValidate() {

    var tokenSession = identitySessionControllerService.tokenSessionValue;
    
    if (tokenSession == null) {
        console.log("sem sessao, iniciando uma nova");
        tokenSessionStart();
    } else {

        console.log("TOKEN: " + tokenSession.accessToken);

        const utcExpires = new Date(0);
        utcExpires.setUTCMilliseconds(tokenSession.expires);
        const utcNow = new Date(Date.now());

        if (utcNow >= utcExpires) {
            console.log("sessao expirada");
            logoutForced();
        } else {
            console.log("sessao existente");
        }
        console.log("NOVO UTC EXPIRA EM:" + utcExpires.toUTCString());
        console.log("UTC AGORA:" + utcNow.toUTCString());
        console.log(tokenSession);
    }
}

function tokenSessionStart() {

    /// Para qualquer timer, para recome�ar checagem do Token
    stopRefreshSessionTokenTimer();

    var appKey = "WorkPanel";
    //var language = "pt_br";
    var language = "en_us";
    var timezone = "SaoPauloBrazil";


    var token = null;
    var ip = "192.168.0.1";
    var device = "desktop";
    var deviceBrandName = "ibm";
    var deviceModel = "lenovo";
    var osPlatform = "windows";
    var osName = "win";
    var osVersion = "11";
    var browserName = "chrome";
    var browserVersion = "91";
    var browserEngine = "webkkit";
    var browserEngineName = "1234";
    //return fetchWrapper.post(`${baseUrl}/session-start`, { appKey, token, ip, device, deviceBrandName, deviceModel, osPlatform, osName, osVersion, browserName, browserVersion, browserEngine, browserEngineName })

    return fetchWrapper.post(`${baseUrl}/session/session-start`, { appKey, language, timezone })
        .then((tokenSession: TokenSessionModel) => {
            // publish user to subscribers and start timer to refresh token
            storeTokenSession(tokenSession);
            tokenSessionSubject.next(tokenSession);
            startSessionUserTimer();

            const utcExpires = new Date(0);
            utcExpires.setUTCMilliseconds(tokenSession.expires);
            const utcNow = new Date(Date.now());

            console.log("NOVO UTC EXPIRA EM:" + utcExpires.toUTCString());
            console.log("UTC AGORA:" + utcNow.toUTCString());
            console.log("TOKEN START: " + tokenSession.accessToken);
            console.log(tokenSession);


            return tokenSession;
        });
}

function tokenSessionRefresh() {

    /// Para qualquer timer, para recome�ar checagem do Token
    stopRefreshSessionTokenTimer();


    //const { token } = sessionUserSubject?.value;

    var tokenSession = identitySessionControllerService.tokenSessionValue;

    var appKey = appKey;
    var tokenRefreshKey = tokenSession.refreshToken;

    //console.log("Refresh Token: Inicio");
    //console.log(tokenSession);


    //console.log("Refresh Token: c�digo chave");
    //console.log(tokenRefreshKey);

    return fetchWrapper.post(`${baseUrl}/session-revalidate`, { appKey,  tokenRefreshKey })
        .then((tokenSession: TokenSessionModel) => {
            // publish user to subscribers and start timer to refresh token
            storeTokenSession(tokenSession);
            tokenSessionSubject.next(tokenSession);

            /// Prepara Timer antes de Expirar Token
            startSessionUserTimer();

            console.log("Token Re-Gerado");
            console.log(tokenSession);
            return tokenSession;

        }).catch(error => {
            logout();
        });
}

let refreshTokenSessionTimeout: any;
//let testeTimeout: any;

function startSessionUserTimer() {
    stopRefreshSessionTokenTimer();
    if (tokenSessionSubject?.value) {
        // parse json object from base64 encoded jwt token
        const { expires } = tokenSessionSubject?.value;
        // const token = userSubject?.value?.accessToken ?? '.';
        // const jwtToken = JSON.parse(atob(token.split('.')[1]));
        // set a timeout to refresh the token a minute before it expires
        // const expires = new Date(jwtToken.exp * 1000);
        const expiry = new Date(expires);
        const timeout = expiry.getTime() - Date.now() - 1000;

        //refreshTokenSessionTimeout = setTimeout(tokenSessionRefresh, timeout);
        //refreshTokenSessionTimeout = setTimeout(tokenSessionRefresh, 10000);
        //console.log("Start Timer Token Session");
    }

    //testeTimeout = setTimeout(testeS, 1000);
}
/*
function testeS() {
    const utcNow = new Date(Date.now());
    console.log(utcNow.toUTCString());

    testeTimeout = setTimeout(testeS, 1000);
}
*/

function stopRefreshSessionTokenTimer() {
    clearTimeout(refreshTokenSessionTimeout);
}



/*
 * SESSION USER
 */

function storeTokenSession(tokenSession: TokenSessionModel) {
    localStorage.setItem(tokenSessionName, JSON.stringify(tokenSession));
}
function getSession(): TokenSessionModel {
    return JSON.parse(localStorage.getItem(tokenSessionName));
}


function logout() {
    // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
    
    var sessionAccessId = tokenSessionSubject.value.claims["sessionAccessId"];
    var sessionUserId = tokenSessionSubject.value.claims["sessionUserId"];

    console.log("Dados para logout");
    console.log(sessionAccessId);
    console.log(sessionUserId);


    /// Interrompe todos os timers
    stopRefreshSessionTokenTimer();

    /*
    fetchWrapper.post(`${baseUrl}/session/session-sign-out`, { })
        .then((tokenSession: TokenSessionModel) => {
            // publish user to subscribers and start timer to refresh token

            storeTokenSession(tokenSession);
            tokenSessionSubject.next(tokenSession);

            startSessionUserTimer();

            //const utcExpires = new Date(0);
            //utcExpires.setUTCMilliseconds(tokenSession.expires);
            //const utcNow = new Date(Date.now());

            //console.log("Novo Token ao fazer Logout:");
            //console.log("NOVO UTC EXPIRA EM:" + utcExpires.toUTCString());
            //console.log("UTC AGORA:" + utcNow.toUTCString());
            //console.log(tokenSession);

            return tokenSession;
        });
*/
    //stopRefreshSessionTokenTimer();
    //userSubject.next(null);

    storeTokenSession(null);
    tokenSessionSubject.next(null);

    clear();
    //history.push('/account/login');
    
}


function logoutForced() {
    // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page



    if (tokenSessionSubject != null) {
        var sessionAccessId = tokenSessionSubject.value.claims["sessionAccessId"];
        var sessionUserId = tokenSessionSubject.value.claims["sessionUserId"];

        fetchWrapper.post(`${baseUrl}/session/session-sign-out`, { appKey, sessionAccessId, sessionUserId });
    }

    stopRefreshSessionTokenTimer();

    // Apagar os dados de sess�o de maneira for�ada
    resetInfoMemory();


    clear();
    //history.push('/account/login');
}


function resetInfoMemory() {

    /// Limpa Mem�ria da Sess�o
    storeTokenSession(null);
    tokenSessionSubject.next(null);

}



/*
function refreshToken() {

    console.log("Refresh Iniciado");

    const { refreshToken } = userSubject?.value;

    var user = identityService.userValue;

    console.log("Params REfresh:");
    console.log(user);
    
    //return fetchWrapper.post(`${baseUrl}/access-tokens/${refreshToken}/refresh`, { appKeyHUB, user }, false)

    return fetchWrapper.post(`${baseUrl}/access-tokens/refresh`, { appKeyHUB, user })
        .then((user: IdentityModel) => {
            // publish user to subscribers and start timer to refresh token

            store(user);
            userSubject.next(user);
            startRefreshTokenTimer();


            const utcExpires = new Date(0);
            utcExpires.setUTCMilliseconds(user.expires);
            const utcNow = new Date(Date.now());

            console.log("NOVO UTC EXPIRA EM:" + utcExpires.toUTCString());
            console.log("UTC AGORA:" + utcNow.toUTCString());

            console.log("Refresh Efetuado");
            return user;
        }).catch(error => {
            logout();
        });
}
*/




function clear() {
    localStorage.setItem(tokenName, null);
}







// helper functions

let refreshTokenTimeout: any;

/*
function checkSession() {
    // parse json object from base64 encoded jwt token
    const { expires } = userSubject?.value;
    // const token = userSubject?.value?.accessToken ?? '.';
    // const jwtToken = JSON.parse(atob(token.split('.')[1]));
    // set a timeout to refresh the token a minute before it expires
    // const expires = new Date(jwtToken.exp * 1000);
    const expiry = new Date(expires);
    const timeout = expiry.getTime() - Date.now() - 1000;

    refreshTokenTimeout = setTimeout(refreshToken, timeout);
}
*/



/*
 * 
 * let testeTimeout: any;

function startRefreshTokenTimer() {
    stopRefreshTokenTimer();
    if (userSubject?.value) {
        // parse json object from base64 encoded jwt token
        const { expires } = userSubject?.value;
        // const token = userSubject?.value?.accessToken ?? '.';
        // const jwtToken = JSON.parse(atob(token.split('.')[1]));
        // set a timeout to refresh the token a minute before it expires
        // const expires = new Date(jwtToken.exp * 1000);
        const expiry = new Date(expires);
        const timeout = expiry.getTime() - Date.now() - 1000;

        refreshTokenTimeout = setTimeout(refreshToken, timeout);
        //refreshTokenTimeout = setTimeout(refreshToken, 10000);
    }

    testeTimeout = setTimeout(teste, 1000);
}

function teste() {
    const utcNow = new Date(Date.now());
    console.log(utcNow.toUTCString());

    testeTimeout = setTimeout(teste, 1000);
}

function stopRefreshTokenTimer() {
    clearTimeout(refreshTokenTimeout);
    clearTimeout(testeTimeout);
}

function store(user: IdentityModel) {
    localStorage.setItem(tokenName, JSON.stringify(user));
}
function get(): IdentityModel {
    return JSON.parse(localStorage.getItem(tokenName));
}




*/
