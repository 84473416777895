import React from 'react';
import { Route, Switch } from 'react-router-dom';


import { Logout } from './Logout';

function Off({ history, match }: any) {
    const { path } = match;
    let background = history.location.state && history.location.state.background;


    return (
        <Switch>
            <Route path={`${path}`} component={Logout} />
        </Switch>
    );
}

export { Off };
