import React, { useEffect, useState } from 'react';
import { alertService, } from '../_services'

import { identitySessionControllerService } from '../_services/identity.session.controller.service'


import Container from '@material-ui/core/Container';



function Reset({ history }) {

    identitySessionControllerService.resetInfoMemory();

    useEffect(() => {
        //window.location.href = dynamicConfigService.GetUrlDefaultAfterLogin();
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <div>
                <span>RESET MEMORY</span>
            </div>
        </Container>
    );
}

export { Reset };